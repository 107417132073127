:root {
    --unit-100vh: 100vh;
}
@supports (height: 100dvh) {
    :root {
        --unit-100vh: 100dvh;
    }
}


.pos-window {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    //min-height: var(--unit-100vh); //100vh;
    //min-height: -webkit-fill-available;
}

.pos--products-wrapper {
    flex: 1;
}

.pos--panel-wrapper {
    background-color: #e2e2e2;
    border-right: solid 3px #787878;

    overflow: hidden;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    //height: 100%;
    max-width: 500px;
}

.pos--products-wrapper {
    height: 100%;
    overflow: hidden;

    .breadcrumb>li a {
        font-size: 20px;
        padding: 10px 5px;
        color: #000;
        display: inline-block;
    }
    .go-back {
        a {
            cursor: pointer;
            &:hover {
                color: #ccc;
            }
        }
    }
}

.has-scroller {
    overflow-x: hidden;
    overflow-y: auto;
}

.pos--products-with-categories-list {
    width: 100%;
    height: 85%;
}

.pos--items--categories,
.pos--items--list {
    padding: 10px;
    position: relative;

    //height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 50px;
}


.pos--cat--item,
.pos--product--item {
    position: relative;
    vertical-align: top;
    display: inline-block;
    line-height: 100px;
    font-size: 11px;
    margin: 8px !important;
    min-width: 200px;
    width: 30%;
    max-width: 300px;
    height: 300px;
    background: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 3px;
    border-bottom-width: 3px;
    overflow: hidden;
    cursor: pointer;

    .item-image {
        position: relative;
        width: 280px;
        height: 280px;
        background: white;
        text-align: center;
        transition: opacity 0.2s ease;
        &:hover {
            opacity: 0.5;
        }
    }

    img {
        max-height: 280px;
        max-width: 280px;
        vertical-align: middle;
    }
    .item-price-tag {
        position: absolute;
        top: 2px;
        right: 2px;
        vertical-align: top;
        color: white;
        line-height: 13px;
        background: #7f82ac;
        padding: 2px 5px;
        border-radius: 2px;
    }
    .item-name {
        position: absolute;
        box-sizing: border-box;
        bottom: 0;
        top: auto;
        line-height: 14px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        background: -webkit-linear-gradient(-90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
        padding: 3px;
        padding-top: 15px;
    }
}


.cart-container {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;
}

.pos--cart {
    background: white;
    padding-bottom: 8px;
    font-size: 16px;
    text-align: left;

    max-width: 500px;
    height: 100%;
    transform: translate3d(0, 0, 0);

    display: flex;
    flex-direction: column;


    .pos--cart-items {
        width: 100%;
        height: 100%;
        background: white;
        flex-grow: 1;
        overflow: hidden;
        overflow-y: auto;
    }


    .pos--cart--item {
        width: 100%;
        margin: 0px;
        padding: 10px 15px;
        cursor: pointer;
        transition: background 250ms ease-in-out;

        &.selected {
            //background: rgba(140, 143, 183, 0.2);
            background: rgb(194, 234, 247);
            transition: background 250ms ease-in-out;
            cursor: default;
        }
        .pos--cart--item-name {
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .pos--cart--item-meta {
            //padding: 5px 0;
        }
        .pos--cart--item-price {
            padding: 0;
            font-weight: bold;
            //float: right;
        }
    }
    .pos--cart-summary {
        width: 100%;
        text-align: right;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;

        .pos--cart-summary-line {
            border-top: 2px solid #bfbfbf;
            height: 0;
        }
        .pos--cart-summary-total {
            padding: 10px;
        }
    }
}

.pos--cart-wrapper {
    //height: calc(100vh - 282px);
    min-height: 380px;
    overflow: hidden;
    flex: 1;
    display: flex;
}

.pos--calc-wrapper {
    display: flex;

    height: 220px;
    //padding: 10px;

    .btn-pay {
      width: 100%;
      height: 4em;
      line-height: 2.5em;
    }
}

.pos-payment-wrapper {
  top: 60px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  background: #fff;
}

.pos-receipt-wrapper {
    top: 60px;
    width: 100%;
    height: 95%;
    position: fixed;
    z-index: 110;
    background: #fff;
}

.pos--cart-summary-total {
    text-align: right;
}

.pos--items--search {
    input {
        height: 50px;
        font-size: 16px;
    }
}

.xdummy {}
