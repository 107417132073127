.ui.table.timesheet_employees-list th,
  .ui.table.timesheet_employees-list td {
    text-align: center;
  }
.timesheet-header{
  background-color: #80808069 !important;
}
.timesheet-sub-header{
  background-color: #80808036 !important;
}

.timesheet-row {
  &.selected {
    background-color: #80808036 !important;
  }
  .timesheet-row__item {
    width: 120px;
    max-width: 120px;
  }

  a {
    color: inherit;
  }

  .off-day, .weekend {
    background-color: #1a8f1a29 !important;
  }
  .vacation-day, .vacation {
    background-color: #f3ff0042 !important;
  }
  .withdraw-day, .withdrawn {
    background-color: #ff00003d !important;
  }
  .timeoff-day, .time_off {
    background-color: #43f16f1f !important;
  }
  .not-attended, .absent {
    color: #ff3838;
    background: #f6e5e5;
  }
  .attendant {
    color: #5f5f5f;
  }
  .overtime-day, .overtime {
    background: rgba(28, 133, 207, 0.19);
  }
  //.work_day {
  //  border: 1px solid green;
  //}

  .overtime {
    &.unpaid { background-color: #DFF2FF !important; }
    &.payslip { background-color: #A3D5FF !important; }
    &.cash { background-color: #75BFEC !important; }

    &.delay { background-color: #e9b110 !important; }
  }

  .delay {
    background-color: #e9b110 !important;
  }

  .emp-salary{
    background-color: #8f1a2829 !important;
  }
  .emp-names{
    background-color: #1c85cf30 !important;
  }
  .emp-ids{
    background-color: grey !important;
  }

  .infractions-indicator {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #FF4532 transparent;
    transform: rotate(45deg);

    z-index: 1;
    position: absolute;
    top: -2px;
    right: -7px;
  }
}
