
tr.total td,
.ui.table tr.total td {
  border-top: 2px solid #08c;
}

td.total {
  background-color: #f5f5f5;
}


// Enhancements in UI
//
.selectize-control.single .selectize-input {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1)
}

.selectize-dropdown {
  box-shadow: 0 1 2px rgba(0, 0, 0, 0.2);
}


// End Enhancements

.field .add-on {
  height: 26px;
}


.invoice-total {
  color: blue;
}

.invoice-total{
  font-size: 18px;
  padding-left: 5px !important;

  &[disabled] {
    background: #fff;
  }
}

.popover-title {
  font-size: 16px;
  text-align: center;
}
