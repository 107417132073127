/*
  ===============================
  Tables
  ===============================
 */

.date-links {
  margin: 5px 0;
}

.table th {
  position: relative;
  font-weight: normal;
  font-family: var(--header-font-family);
  font-size: 15px;
}

table td, table tr {
  transition: all 0.3s ease-in-out;
}

.table.table-td-centered {
  th, td {
    text-align: center;
  }
}

.js-delete-col {
  display: none;
}


.table.table-toggle {
  & > tbody > tr:nth-child(2n) {
    display: none;
  }
}

tr.bg td, tr.bg th {
  background: #f5f5f5;
}

tr.bg.orange td, tr.bg.orange th {
  background: #F9E3BE !important;
}

tr.border-top {
  td, th {
    border-top: 2px solid rgb(143, 134, 134);
  }
}

.tr-result {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: red;
}

td.sep {
  border-top: 4px solid #ddd !important;
  background: #f5f5f5;
}

tr.sep td {
  border-top: 4px solid #ddd !important;
}

.table tbody tr.active td {
  background-color: #f5f5f5;
}

tr.u-v-align td {
  vertical-align: middle !important;
}

.table-actions {
  margin-bottom: 10px;
}

.table.table-td-centered td.text-right {
  text-align: right;
}

.table.table-td-centered td.text-left {
  text-align: left;
}


table > tbody > tr > td.ledger-account-cell::before {
  content: "";
  display: inline-block;
  vertical-align: top;
  min-height: 40px;
}

.sticky-table-wrapper {
  max-height: 800px;  /* Enable vertical scrolling */
  max-width: 100%;    /* Prevent overflow beyond layout */
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
  /*white-space: nowrap; !* Prevent table cells from wrapping *!*/

  table {
    width: 100%; /* Ensures it stays within the wrapper */
    /*table-layout: fixed; !* Prevents content from expanding unpredictably *!*/
  }

  thead {
    position: sticky;
    top: 0; /* Make sure it sticks at the top of the wrapper */
    background: #fff;
    z-index: 10; /* Ensure it stays above other elements */
  }
}
