/*
  ==================
  Layout
  ==================
 */


body {
  font-family: var(--tahoma-font-family);
  font-size: 15px;
  background: #f9f9f9;
}


.page-container {
  // margin-top: 82px;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.page-grid {
  margin-top: var(--top-bar-height);

  display: flex;
  flex: 1;
  flex-direction: column;
  // height: 100%;
  overflow: auto;
  transition: all 0.2s ease;
}


#page {
  margin-top: 0;
  margin-bottom: 62px;

  //margin-left: 14px; // fix for scroll issue
  //width: calc(100% - 14px);
}


.page-header--wrapper {
  background: #e1f0ff;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;

  .breadcrumb {
    background: #e1f0ff;

    & li a {
      color: #93aec1;
    }
  }
}

.page-header {
  border-bottom: 1px solid #eee;

  margin: 0 0 1rem;
  padding: 10px 0 0 0;

  border: none;

  color: #000;
  word-wrap: break-word;
  // word-break: break-all;

  display: flex;


  h1 {
    margin-bottom: 0;
    border: none;
    color: #0d75bc;
  }

  small {
    color: #000;
  }

  img {
    vertical-align: middle;
  }

}

.page-header--title {
  @extend .animated !optional;
  @extend .an-fadeIn !optional;

  //max-width: 50%;
  flex: 0 0 50%;
}

.page-header--actions {
  &:empty {
    display: none;
  }
}

.page-header--bottom {
  @extend .animated !optional;
  @extend .an-fadeIn !optional;

  .ui.label {
    background: #fff;
  }
}


@media (max-width: 576px) {
  .page-header,
  .page-header h1 {
    margin: 0 !important;
    padding: 0 !important;
  }

  .page-header--wrapper .breadcrumb {
    padding: 5px 0;
  }
}


/*
  =========================
  overrides
  =========================
 */

button,
textarea {
  font-family: var(--tahoma-font-family);
}

select,
.pname {
  font-family: var(--naskh-font-family);
}


h1, h2, h3, h4, h5, h6 {
  font-family: var(--header-font-family);
  //font-weight: normal !important;
}

label {
  font-family: var(--tahoma-font-family);
}

//
//h1 {
//  font-size: 30px;
//}

h1 small {
  font-size: 20px;
}

//h1, h2, h3 {
//  line-height: 34.5px;
//}

p, ol, ul {
  font-family: var(--tahoma-font-family);
  font-size: 16px !important;
}

td {
  font-family: var(--tahoma-font-family);
  font-size: 15px !important;
}

p { margin: 0; }

ul { margin: 0; padding: 0;}



.page-actions {
  padding: 10px;
  background: #fff;
  margin-right: 0;
  margin-left:0;
  margin-bottom: 20px;
  transition: all 0.2s ease-in;

  &:after {
    clear: both;
    display: table;
    content: " ";
  }

  &:empty {
    //display: none !important;
    background: transparent;
    margin-bottom: 0;
    //padding: 0;
  }
}



.table {
  .label.num {
    min-width: 80px;
    text-align: left;
  }
}



// .top-nav-logo {
//   background: #7300ff !important;
// }


.headroom--pinned {
  transform: translateY(0);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.page-container, .main.menu {
  // width: 100%;
  //transform: translate3d(0,0,0);
  transition: transform .15s cubic-bezier(.2,.3,.25,.9);
}



.flex {
  display: flex;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-none {
  flex: none;
}

// ref: https://tobiasahlin.com/blog/flexbox-break-to-new-row/
.flex-break-row {
  flex-basis: 100%;
  height: 0; // I think It should be <width: 0>;
}

.flex-break-column {
  flex-basis: 100%;
  width: 0;
}


.basic-header {
  //margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}



.ui.main.menu .item.global-search-btn {
  padding: .9em 1.6em .9em .8em;
}
