
.unit, .product-unit {
  // display: inline-block;
  font-family: var(--tahoma-font-family);
  padding-right: 2px;
}

/*
  =====================
    Print + Invoice
  ==================
 */
.print-only, .visible-print { display: none; }

.invoice-logo-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    // width: 300px;
    position: absolute;
    top: -15px;
    left: 0;
  }
}

th.logo-th {
  height: 30px !important;
}

td, th {
  vertical-align: middle;

  .bordered {
    border: 1px solid #ccc;
  }
}
