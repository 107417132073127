.sales-order-stores-list {
    margin-right: 0 !important;
    padding: 20px;
    list-style: none;
    li {
        margin-bottom: 5px;
        padding: 5px !important;
        background: #fbfbfb;
        border-right: 2px solid #b3b3b3;
        cursor: pointer;
        &:before {
            content: '' !important;
        }
        &:hover {
            background: #f1f1f1;
        }
    }
    .store-name-in-list {
        display: inline-block;
        width: 200px;
    }
}

// .sales-order-fulfillment-stores-list {
//   padding: 20px;
//   li {
//     margin: 10px;
//     border-bottom: 1px solid #ccc;
//   }
//   .store-name-in-list {
//     display: inline-block;
//     width: 200px;
//   }
// }
.selectize-control.product-dropdown {
    .p-quantity {
        float: left;
    }
}
.ui.label.so-status-label {
    border-radius: .28571429rem;
}

.so-status {
    background: #efefef;
    padding: 3px 5px;
    border-radius: 15px;
    width: 107px;
    .so-status-label {
        display: inline-block;
        text-transform: capitalize;
    }
    .so-status-shape {
        width: 1em;
        height: 1em;
        display: inline-block;
        margin: 0 5px;
        border-radius: 50%;
        background: transparent;
        position: relative;
        overflow: hidden;
        top: 2px;
        &.status-draft {
            background: #fbcf3c;
        }
        &.status-active {
            background: #69cf34;
        }
        &.status-finalized {
            background: #4397cb;
        }
        &.status-fulfilled {
            background: #9a5fcb;
        }
        &.status-void {
            background: #f83722;
        }
    }
}

.so-extended-status {
    padding: 5px;
    text-align: center;
    &.align-right {
        text-align: right;
    }
    &.align-left {
        text-align: left;
    }
    .so-status-shape {
        width: 1em;
        height: 1em;
        display: inline-block;
        margin: 0 5px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid rgba(0,0,0,0.6);
        position: relative;
        overflow: hidden;
        &.empty {
            border: 1px solid rgba(0,0,0,0.2);
        }
        &.full {
            background: rgba(0,0,0,0.6);
        }
        &.partial:after {
            content: '';
            background: rgba(0,0,0,0.4);
            height: 0.5em;
            display: block;
            bottom: 0;
            position: absolute;
            width: 100%;
        }
    }
}


.so-status-simple-label {
    background: #f7f7f7;
    padding: 4px 5px;
    border-radius: 15px;
    width: 122px;
    height: 29px;
    display: inline-block;

    &.white-bg {
        background: #fff;
    }


    .status-label {
        display: inline-block;
        text-transform: capitalize;
        color: var(--grey-color);
        font-size: 13px;
        top: -2px;
        position: relative;

        font-family: var(--base-font-family);
    }

    .status-shape {
        width: 1em; height: 1em;
        display: inline-block;
        margin: 0 5px;
        border-radius: 50%;
        background: transparent;
        position: relative;
        overflow: hidden;
        top: 2px;
        font-size: 15px;

        &.status-draft { background: #FBBD08; }
        &.status-active { background: #21BA45; }
        &.status-finalized { background: #2185D0; }
        &.status-fulfilled { background: #A333C8; }
        &.status-void { background: #DB2828; }

        //positions
        &.status-open { background: #21BA45; }
        &.status-on_hold { background: #A333C8; }
        &.status-filled { background: #2185D0; }
        &.status-canceled { background: #DB2828; }
    }
}



.label.store-name {
    cursor: pointer;
    background: #f7f7f7;
}

.label.qty-label {
    color: #fff;
    &.color-black {
        color: #000;
    }
    &.qty-lt-safty_quantity {
        background-color: #ff0000;
    }
    &.qty-safty_quantity {
        background-color: #c71585;
    }
    &.qty-reorder_quantity {
        background-color: #d4d40d;
        color: #000;
    }
    &.qty-available {
        background-color: #008000;
    }
    &.qty-max_quantity {
        background-color: #00008b;
    }
    &.qty-gt-max_quantity {
        background-color: #800080;
    }
}

.so-files-wrapper {
    &.fullscreen {
        position: fixed;
        top: var(--top-bar-height);
        right: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 10px;
        padding-top: 30px;
        padding-bottom: 50px;
        z-index: 102;
        overflow-y: auto;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
        border-radius: 10px;
    }
    &::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #AAA;
        border-radius: 10px;
        background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, .2) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%, rgba(0, 0, 0, .2) 75%, transparent 75%, transparent)
    }
}


.selectize-dropdown.single.product-dropdown {
    width: 450px !important;
    right: 0 !important;
    left: auto !important;
    margin-top: 4px;
    border-top: 1px solid #d0d0d0;
    .selectize-dropdown-content {
        max-height: 300px;
        &>div {
            font-size: 16px;
            line-height: 1.5em;
            .xpname {
                width: 300px;
                display: inline-block;
                font-family: 'Droid Arabic Naskh', Tahoma;
            }
        }
    }
}

.duplicated {
    background-color: #ff8c8c;
}

.form-field.low-quantity {
    background-color: #7fd8d5;
}

.sales-order-form-wrapper .add-contact-btn {
    margin-top: 24px;
}


.form-items-list-enter-active, .form-items-list-leave-active {
    transition: opacity 0.5s, display 0.5s;
}
.form-items-list-enter, .form-items-list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    //transform: translateY(30px);
}

.order-item--product-name {
  //font-weight: 600;
    display: block;
    cursor: pointer;
    padding: 10px;
}


.ui-product-description-modal {
    .description {
        font-size: 16px;
        line-height: 1.5em;
        padding-right: 40px;

        li {
            line-height: 1.5em;
        }
    }
}

.none {}
