#page {
  padding: 0;
}

.ui.main.menu > .container {
  padding-right: 0;
}

@media only screen and (max-width: 767px) {
  .ui.modal > .close {
    left: 0.5rem !important;
    right: auto !important;
  }

  #page {
    padding: 0;
  }

  #menuwrapper ul li ul.open {
    width: 40vw;
  }

  .top-search-component {
    //display: none !important;
    width: 100px;
  }

  .page-actions {
    margin-right: 0;
    text-align: center;

    .btn {
      display: inline-block;
      // width: 25%;
      margin-bottom: 10px;
    }

    .btn-group {
      width: 100%;
    }
  }

}

@media only screen and (max-width: 992px) {
  .ui.modal > .header {
    padding-right: 4.25rem;
  }
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  #menuwrapper ul li ul.open {
    width: 50vw;
  }

  #menuwrapper ul li ul li {
    width: 100% !important;
  }

  .toggle-sidebar {
    display: block;
  }

  .new-invoice-dropdown,
  .ui.menu:not(.vertical) .item.global-add-btn,
  .ui.menu:not(.vertical) .right.item.recent-invoices-list,
  .new-invoice-dropdown {
    display: none !important;
  }
  .top-search-component {
    width: 100px;
  }

  .ui.modal > .close {
    left: 0.5rem !important;
    right: auto !important;
  }

  .draft-transactions-wrapper {
    display: none !important;
  }

  .m-table-actions > a {
    display: none !important;
  }

  .table-actions,
  .page-actions {
    margin-right: 0;

    .btn {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }

    .btn-group {
      width: 100%;
    }
  }

  .btn-group-responsive {
    .pull-left, .pull-right { float: none !important; }
    .btn {
      display: block;
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 10px;
    }
    .btn:last-child { margin-bottom: 0px; }
  }


  body.sessions_new .btn,
  body.registrations_new .btn {
    width: 100%;
  }


  .products-search-view .cat-name {
    display: none;
  }

  .products-search-view .p-item {
    margin-left: 0;
  }

  .form-search {
    min-width: 100%;
  }

  .daterangepicker.show-calendar .calendar {
    display: none;
  }
  .daterangepicker .ranges li:last-child {
    display: none;
  }

  .pagination .pagination-page,
  .pagination .pagination-first-page,
  .pagination .pagination-last-page,
  .pagination .disabled {
    display: none !important;
  }

  .pagination ul > li > a {
    border-left-width: 1px;
  }

  .ui.five.statistics .statistic {
    min-width: 100%;
  }

  .btn-print-page {
    display: none;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .global-search-btn {
    display: none !important;
  }

  .top-nav-logo {
    .sm {
      display: none;
    }
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  .top-nav-logo {
    .sm {
      display: none;
    }
  }
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
  .toggle-sidebar {
    display: block;
  }

  .top-search-component,
  .ui.menu:not(.vertical) .item.ui.menu:not(.vertical) .item.global-add-btn,
  .ui.menu:not(.vertical) .right.item.recent-invoices-list,
  .new-invoice-dropdown {
    display: none;
  }
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
  .toggle-sidebar {
    display: block;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  .toggle-sidebar {
    display: block;
  }
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
  .toggle-sidebar {
    display: block;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
  .toggle-sidebar {
    display: block;
  }

  body.sessions_new .btn,
  body.registrations_new .btn {
    width: 100%;
  }

  .top-search-component,
  .ui.menu:not(.vertical) .item.global-add-btn,
  .ui.menu:not(.vertical) .right.item.recent-invoices-list,
  .new-invoice-dropdown {
    display: none !important;
  }
}


/* LG G4 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 4){
  .toggle-sidebar {
    display: block;
  }

  body.sessions_new .btn,
  body.registrations_new .btn {
    width: 100%;
  }

  .top-search-component,
  .ui.menu:not(.vertical) .item.global-add-btn,
  // .recent-invoices-list .txt,
  .ui.menu:not(.vertical) .right.item.recent-invoices-list,
  .new-invoice-dropdown {
    display: none !important;
  }

  .daterangepicker.show-calendar .calendar {
    display: none;
  }

  .products-search-view .cat-name {
    display: none;
  }

  .products-search-view .p-item {
    margin-left: 0;
  }
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
  .toggle-sidebar {
    display: block;
  }

  body.sessions_new .btn,
  body.registrations_new .btn {
    width: 100%;
  }

  .top-search-component,
  .ui.menu:not(.vertical) .item.global-add-btn,
  // .recent-invoices-list .txt,
  .ui.menu:not(.vertical) .right.item.recent-invoices-list,
  .new-invoice-dropdown {
    display: none !important;
  }

  .products-search-view .cat-name {
    display: none;
  }

  .products-search-view .p-item {
    margin-left: 0;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
  body.sessions_new .btn,
  body.registrations_new .btn {
    width: 100%;
  }

  .top-search-component,
  .ui.menu:not(.vertical) .item.global-add-btn,
  // .recent-invoices-list .txt,
  .ui.menu:not(.vertical) .right.item.recent-invoices-list,
  .new-invoice-dropdown {
    display: none !important;
  }


  .products-search-view .cat-name {
    display: none;
  }

  .products-search-view .p-item {
    margin-left: 0;
  }

  .toggle-sidebar {
    display: block;
  }
}
