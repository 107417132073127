
.products-form {
  &.service {
    .product-units-wrapper,
    .product-stocks-wrapper,
    .product-more-wrapper,
    .product-fees-wrapper,
    .product-variants-wrapper {
      display: none;
    }
  }
}


.product-image-thumb {
  width: 40px;
}

.products-list-table > tbody > tr > td {
  vertical-align: middle;
}

.product-unit {
  color: #a7a7a7;
}
