//$font-size: 16px;
//$line-height: $font-size * 1.6;
//$h1-size: $font-size * 2; // all other fonts are related to $font-size.


:root {
  --naskh-font-family: 'Droid Arabic Naskh', Rubik, system-ui, -apple-system, "Segoe UI", Roboto, sans-serif !important;
  --kufi-font-family: 'Droid Arabic Kufi', Rubik, system-ui, -apple-system, "Segoe UI", Roboto, sans-serif !important;
  --base-font-family: Gess, Rubik, system-ui, -apple-system, "Segoe UI", Roboto, sans-serif !important;
  --base-en-font-family: Rubik, system-ui, -apple-system, "Segoe UI", Roboto, sans-serif !important;
  --header-font-family: GESSTwoMedium, Rubik, system-ui, -apple-system, "Segoe UI", Roboto, sans-serif !important;
  --numeric-font-family: Rubik, system-ui, -apple-system, "Segoe UI", Roboto, sans-serif !important;
  --tahoma-font-family: Tahoma, Rubik, system-ui, -apple-system, "Segoe UI", Roboto, sans-serif;

  --top-bar-height: 62px;

  --grey-color: #767676;

  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;

  --green-color: #00a651;
  --green-color-light: #00c781;
  --green-color-dark: #007a3d;

  --bs-green-100: #d1e7dd;
  --bs-green-200: #bae6c9;
  --bs-green-300: #a3cfbb;
  --bs-green-400: #8fd3a4;
  --bs-green-500: #77d394;
  --bs-green-600: #63ca7a;
  --bs-green-700: #4fb571;
  --bs-green-800: #3b8f4d;
  --bs-green-900: #22743e;
}


.color-gray {
  color: var(--bs-gray);
}

.color-gray-dark {
  color: var(--bs-gray-dark);
}

.color-gray-100 {
  color: var(--bs-gray-100);
}

.color-gray-200 {
  color: var(--bs-gray-200);
}

.color-gray-300 {
  color: var(--bs-gray-300);
}

.color-gray-400 {
  color: var(--bs-gray-400);
}

.color-gray-500 {
  color: var(--bs-gray-500);
}

.color-gray-600 {
  color: var(--bs-gray-600);
}

.color-gray-700 {
  color: var(--bs-gray-700);
}

.color-gray-800 {
  color: var(--bs-gray-800);
}

.color-gray-900 {
  color: var(--bs-gray-900);
}

.color-green {
  color: var(--green-color);
}

.color-green-light {
  color: var(--green-color-light);
}

.color-green-dark {
  color: var(--green-color-dark);
}

.color-green-100 {
  color: var(--bs-green-100);
}

.color-green-200 {
  color: var(--bs-green-200);
}

.color-green-300 {
  color: var(--bs-green-300);
}

.color-green-400 {
  color: var(--bs-green-400);
}

.color-green-500 {
  color: var(--bs-green-500);
}

.color-green-600 {
  color: var(--bs-green-600);
}

.color-green-700 {
  color: var(--bs-green-700);
}

.color-green-800 {
  color: var(--bs-green-800);
}

.color-green-900 {
  color: var(--bs-green-900);
}
