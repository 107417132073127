﻿/*
  Gess
 */

@font-face {
  font-family: 'Gess';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/ge_ss_two_light-webfont.woff2') format('woff2');
  //src: local('☺'), url('../fonts/ge_ss_two_light-webfont.ttf') format('truetype');
  unicode-range: U+0600-06FF;

}

@font-face {
  font-family: 'GESSTwoMedium';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/ge_ss_two_medium-webfont.woff2') format('woff2'),
  url('../fonts/ge_ss_two_medium-webfont.ttf') format('truetype');
  unicode-range: U+0600-06FF;
}


/*
  Droid Arabic
 */

@font-face{
  font-family:"Droid Arabic Kufi";
  font-display: swap;
  src: url("../fonts/droid-kufi-font/woff/DroidKufi-Regular.woff") format("woff"), /* Modern Browsers */
  url("../fonts/droid-kufi-font/ttf/DroidKufi-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  unicode-range: U+0600-06FF;
}

@font-face{
  font-family:"Droid Arabic Naskh";
  font-display: swap;
  src: url("../fonts/droidnaskh/woff/DroidNaskh-Regular.woff") format("woff"), /* Modern Browsers */
  url("../fonts/droidnaskh/ttf/DroidNaskh-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  unicode-range: U+0600-06FF;
}

/*
  Numbers font
*/

@font-face {
  font-family: 'Montserrat-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat-Regular'), url("../fonts/Montserrat-Regular.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat-Bold'), url("../fonts/Montserrat-Bold.woff") format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url("../fonts/opensans-regular-webfont.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url("../fonts/opensans-semibold-webfont.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../fonts/opensans-bold-webfont.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}


/* */


@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/rubik-v12-latin-regular.woff2") format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/rubik-v12-latin-500.woff2") format('woff2');
  font-display: swap;
}
