.toggle-sidebar {
  display: none;
}


.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}


/*
  ============================
    Sessions
  ============================
 */

body.sessions_new,
body.home_landing,
body.new_employment_application,
body.saved_application,
body.job_offer,
body.saved_offer,
body.registrations_new,
body.registrations_create {
  background: #e5f4ff;

  .page-container {
    padding-top: 0;
  }
  .page-grid {
    margin-top: 0 !important;
    margin-right: 0 !important;
  }
  .new-session-logo {
    display: block;
    margin-top: 200px;
    margin-bottom: 50px;
  }
  input.password,
  input.string {
    width: 300px;
    text-align: right;
  }
  #user_remember_me {
    float: none;
  }
  .btn {
    // max-width: 300px;
    margin: 0 auto; // margin-bottom: 0;
  }
  .checkbox {
    margin-bottom: 20px;
  }
  .checkbox .controls {
    display: inline-block;
    margin-right: 20px;
  }
  .control-group.checkbox {
    label {
      display: inline;
    }
  }

  .panel-body {
    padding: 30px;
  }

  .ui.form .field {
    margin-bottom: 2em;
  }
}


/*
  ==================================
  Move quantity form
  ==================================
 */

#move-quantity-modal {
  .from-store,
  .current-quantity {
    border: 1px solid #ccc;
    padding: 6px 10px;
    min-width: 200px;
    display: inline-block;
  }
}


/*
  ==================================
  Barcode page
  ==================================
 */

.barcodes-wrapper {
  margin: 0 auto;
  padding: 0;
}

.barcode-item {
  float: right;
  text-align: center;
  padding-bottom: 2px;
  margin-bottom: 5px;
  &.size-30x22 {
    width: 38mm !important; //33mm; //35mm;
    height: 19mm !important; //20mm;
  }
  .barcode-chart {
    height: 46px !important;
  }
  .barcode-number {
    font-size: 10px; // font-weight: bold;
    position: relative;
    top: -20px;
    font-family: helvetica !important;
    height: 24px !important;
  }
}


/*  =============================== */


/*
  ===============================
  Permissions
  ===============================
 */


.permissions-panel {
  box-shadow: none;

  & > .panel-heading {
    background-color: #fbfbfb;
  }
}

.permission-wrapper {
  clear: both;
  margin-bottom: 2px;

  &:hover {
    background: #f1f1f1;
  }

  &.active {
    background: #c6f3ca;
  }
}

.permission-group {
  margin-bottom: 20px;
}


/* =============================== */


/*
  ===============================
  Products
  ===============================
 */

.primary-unit-label {
  display: inline-block;
  margin-top: 28px;
  margin-right: 20px;
}

// Units table in products/new
.table.units-table>tbody>tr>td {
  padding: 5px 0;
}

.table.units-table table>tbody>tr>td {
  padding: 2px 0;
}


/* ================================== */

.products-form {
  margin-bottom: 200px;
  label {
    display: inline-block;
    min-width: 100px;
  }

  .ui.checkbox label {
    min-width: auto;
  }

  .ui.divider {
    margin: 1rem 0 2rem 0;
  }
}


/*
  Product Categories
 */

// Categories tree
.categories-tree {
  li:hover {
    background-color: #DDF0F8; // box-shadow: inset 0px 2px 1px rgba(0,0,0,0.075);
  }
  .cat-product-count {
    color: #c3c3c3;
    display: inline-block;
    padding-left: 9px;
  }
  .depth-1 {
    margin-right: 40px;
  }
  .depth-2 {
    margin-right: 80px;
  }
  .depth-3 {
    margin-right: 120px;
  }
}


/* Categories/products modal */

.cat-product-map-view {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  .p-item {
    background-color: #f3f3f3;
    margin-bottom: 4px;
    border: 1px solid #ccc;
    &.selected {
      opacity: 0.5;
    }
  }
}

.cats-wrapper {
  .c-item {
    background-color: #f3f3f3;
    margin-bottom: 4px;
    border: 1px solid #ccc;
  }
}

.products-search-view {
  .search-wrapper {
    padding-left: 10px;
  }
  #results {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
  }
  .cat-name {
    float: left;
    width: 100px;
  }
  .p-item {
    padding: 15px 10px; // border-bottom: 1px solid #ccc;
    background: #f7f7f7;
    font-size: 1.1em;
    border-right: 1px solid #ccc;
    margin-left: 100px;
    margin-bottom: 5px;
    &:hover {
      background: #f3f3f3;
      border-right: 2px solid #ccc;
    }
    .num {
      font-weight: bold;
      font-size: 1.2em;
      letter-spacing: 1px;
      margin-top: -10px;
    }
    .p-item-q {
      background-color: #dcebfd;
    }
    .p-item-price {
      background-color: #b3d6ff;
    }
  }
}


/* ================= */

.break-word {
  word-break: break-all;
}

.ui.statistics .statistic.boxed {
  padding: 20px 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.02);
  background: #fff;

  border: 1px solid #ccc;
  //margin: 0 10px 2em 10px !important;
  min-width: 23% !important;
  border-radius: 4px;

  margin-left: 10px !important;

  &:last-child {
    margin-left: 0!important;
  }

  .value {
    font-family: var(--numeric-font-family);
  }
  .label {
    font-family: 'Tahoma';
    font-weight: normal;
  }
}

.bg-very-light-pink {
  background-color: #F1F5F7;
}

.bg-white {
  background-color: #fff;
}

//.radio>label {
//  float: right;
//}

.search-form-wrapper {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  form {
    margin-bottom: 0;
  }
}

.input-group,
.ui.form .input-group {
  position: relative;
  .input-group-addon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  input[type="text"] {
    padding-right: 30px;
  }
}

.big-num {
  font-size: 36px;
}

.btn-xs {
  padding: 2px 5px;
}

.dropdown-wide {
  width: 500px;
}

.total-item-wrapper {
  margin-bottom: 20px;
  line-height: 26px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  p {
    margin-bottom: 5px;
  }
  .total {
    font-size: 26px;
  }
}

.ui.menu .item>.input input {
  height: 34px;
}

.bottom-border-only {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.top-nav .branch-name {
  color: #4AC2CF;
  font-family: var(--header-font-family);
}

.varaints-table-wrapper {
  background: #fff;
  margin-top: 10px; // box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
}

.alert li {
  margin-right: 20px;
}

.double-border-bottom {
  border-bottom: 3px double #9c9c9c;
}

.top-border {
  border-top: 2px solid #8c8c8c;
}


.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-image: none;
}

.datepicker.dropdown-menu th,
.datepicker.datepicker-inline th,
.datepicker.dropdown-menu td,
.datepicker.datepicker-inline td {
  padding: 7px 11px;
}

tr.form-field {
  vertical-align: middle;
}

tr.form-field.error,
tr.form-field.soft-error {
  // border: 1px solid #e8a1a1 !important;
  background: #ffd7d7;
}

tr.form-field.duplicated {
  border-right: 2px solid #ff9d9d !important;
}

.simple-date-picker {
  direction: ltr;
}

[data-behavior="datepicker"] {
  direction: ltr;
}

// Fix for web-console gem
.console {
  direction: ltr;
}

.print-page {
  margin-bottom: 20px;
  border: 1px solid #bbb;
}

.so-invoice-footer-wrapper {
  width: 100%;
}


.bip-product-table-tax {
  padding: 5px 10px;
  background: #f1f1f1;
  border-radius: 2px;
  border: 1px solid #E9E9E9;

}

.bip-product-table-tax[data-bip-value='true'] {
  color: #088308;
}

.bip-product-table-tax[data-bip-value='false'] {
  color: #FD3434;
}


//.pagy-pager {
//  .left.chevron.icon:before {
//    content: "\f054";
//  }
//
//  .right.chevron.icon:before {
//    content: "\f053";
//  }
//}


.ui.selection.dropdown.text-right .menu>.item {
  text-align: right;
}


.date-range-picker-field {
  background: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


/*
Accounting
 */

.accounting-journal-item-header {
  font-size: 1.2rem;
  font-weight: 600;

  border-top: 1px solid #ccc;
  padding-top: 15px;
}


.journal-table {
  tr > th { border-top: none; }

  .account-name {
    font-size: 1.1em;
  }
}


.journal-entry .post-to-ledger-btn {
  display: inline-block;
}
.journal-entry .posted-label {
  display: none;
}

.journal-entry.posted-to-ledger .post-to-ledger-btn {
  display: none;
}

.journal-entry.posted-to-ledger .posted-label {
  display: inline-block;
}

.fi-account-items-wrapper {
  .ui.selection.dropdown {
    min-width: 8rem;
  }

  table {
    min-width: 1200px;
  }
}


.dashboard-item {
  position: relative;
  display: inline-block;

  .starred, .favorite-link {
    position: absolute;
    top: 1px;
    left: 5px;
    visibility: hidden;

    font-size: 1.2rem;
    color: grey;

    &.active {
      color: #2373ff;
    }
  }

  &:hover .starred, &:hover .favorite-link {
    visibility: visible;
  }
}

.dashboard-list-group .ui.button {
  z-index: 1;
}

@media (min-width: 768px) {
  .fi-account-items-wrapper {
    table {
      min-width: 1200px;
    }
  }
}
