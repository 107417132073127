.offline-ui {
    direction: ltr;
}


/* Twitter DropDown */

.tt-dropdown-menu {
    direction: rtl;
}

// Sweet Alert Plugin
.sweet-alert {
    direction: ltr;
    &>p,
    &>h2,
    &>button {
        direction: rtl;
    }
}


/* DateRangePicker */

.date-range-input-picker {
    background: #fff;
    cursor: pointer;
    padding: 7px 10px;
    border: 1px solid #ccc;
    width: 100%;
}

.daterangepicker .ranges li {
    text-align: right;
}

.daterangepicker td.in-range {
    background: #79F29A;
}

.daterangepicker td.off.in-range {
    color: #76CD99;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background: #35BD72;
    border-color: #59A930;
}

.daterangepicker td.start-date {
    border-radius: 0 4px 4px 0;
}

.daterangepicker td.end-date {
    border-radius: 4px 0 0 4px;
}

.daterangepicker .calendar-time {
    direction: ltr;
    padding-bottom: 10px;
    padding-top: 5px;
    .icon-time {
        margin-right: 5px;
        margin-top: 4px;
    }
}

.daterangepicker_input {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    padding: 5px;
    margin-bottom: 5px;
    background: #f1f1f1;
    height: 55px;
    position: relative;
    overflow: hidden;
    .time-toggle {
        position: absolute;
        top: auto !important;
        bottom: 1px !important;
        right: 50% !important;
        left: 50% !important;
    }
}

.daterangepicker_input .icon-calendar {
    margin-right: 5px;
    margin-top: 4px;
}

.daterangepicker_input .input-mini {
    width: auto;
    height: 33px;
    padding: 5px 14px;
}

.daterangepicker .ranges .range_inputs>div {
    float: right;
}

.daterangepicker .ranges .range_inputs>div:nth-child(2) {
    padding-left: 0;
    padding-right: 11px;
}

.daterangepicker .daterangepicker_start_input label,
.daterangepicker .daterangepicker_end_input label {
    text-align: right;
}

.daterangepicker.opensright .ranges,
.daterangepicker.opensright .calendar {
    float: left;
    margin: 10px;
}

.daterangepicker.opensright:before {
    right: 9px;
    left: auto;
}

.daterangepicker.opensright:after {
    right: 10px;
    left: auto;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
    height: 26px;
}

.daterangepicker_input input {
    height: 30px;
}

.daterangepicker_input>.calendar-time>div {
    display: inline-block;
}

.fa.fa-chevron-left.glyphicon.glyphicon-chevron-left {
    display: inline-block;
    width: 14px;
    height: 14px;
    *margin-right: .3em;
    line-height: 14px;
    vertical-align: text-top;
    background-image: url("vendor/images/glyphicons-halflings.png");
    background-position: 14px 14px;
    background-repeat: no-repeat;
    margin-top: 1px;
    background-position: -432px -72px;
}

.fa.fa-chevron-right.glyphicon.glyphicon-chevron-right {
    display: inline-block;
    width: 14px;
    height: 14px;
    *margin-right: .3em;
    line-height: 14px;
    vertical-align: text-top;
    background-image: url("vendor/images/glyphicons-halflings.png");
    background-position: 14px 14px;
    background-repeat: no-repeat;
    margin-top: 1px;
    background-position: -456px -72px;
}

// i.icon.angle.right:before {
//   content: "\f105";
// }
// i.icon.angle.left:before {
//   content: "\f104";
// }

/*
  ==================================
  Noty
  ==================================
 */


/* Fix datepicker */

.datepicker th,
.datepicker td {
    font-family: verdana, arial, helvetica, sans-serif !important;
}

.datepicker-inline {
    width: 321px;
}


/*
  =======================
  Selectize
  =======================
*/


/* Fix selectize */

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
    // direction: ltr;
    text-align: right;
    font-size: 1.1em;
    font-family: tahoma;
}

.selectize-control.selectize-num.single .selectize-input,
.selectize-control.selectize-num.single .selectize-input input {
    text-align: center;
}

.selectize-control.single .selectize-input {
    background-image: none;
}


/* Selectize for unit price */

.selectize-control.unit-price .selectize-input>div,
.selectize-control.unit-price .selectize-input>input,
.selectize-control.unit-price .selectize-dropdown [data-selectable],
.selectize-control.unit-price .selectize-dropdown .create {
    font-family: Arial;
}

.selectize-dropdown [data-selectable],
.selectize-dropdown .optgroup-header {
    padding: 12px 8px;
}

.selectize-control.single .selectize-input,
.selectize-dropdown.single {
    border-color: #f7f7f7;
}

.selectize-input {
    // control height
    padding: 8px 8px; // control height [top, bottom]
    box-shadow: none;

    &.input-active,
    &.not-full {
        padding: 8px;
    }
    input {
        height: 24px !important;
    }
    &.input-active input {
        height: 24px !important;
    }
    >.item {
        line-height: 22px;
        height: 24px;
    }
    &.input-active>.item {
        // margin-right: 6px;
        position: relative;
    }
}

.selectize-control.multi .selectize-input > div {
    border: none;
}

.selectize-control.multi .selectize-input [data-value] {
    background-image: none;
    box-shadow: none;
    text-shadow: none;
}

.selectize-control.selectize-num {
    .selectize-input.input-active>.item {
        margin-right: 6px;
    }
}

.selectize-control.single .selectize-input:after {
    display: none;
}

.selectize-dropdown .optgroup-header {
    background: #f3f3f3;
    font-size: 1em;
    line-height: 1em;
    padding-top: 8px;
    text-align: left;
}

.control-group.selectized .selectize-control {
    height: 45px;
    display: inline-block;
    vertical-align: middle;
}

// Dropdown direction plugin
.selectize-input.dropdown-active {
    &[data-dropdown-direction='up']::before {
        top: 0;
    }
    &[data-dropdown-direction='down']::before {
        bottom: 0;
    }
}

.selectize-input {
    &.dropdown-active[data-dropdown-direction='down'] {
        border-radius: 3px 3px 0 0;
    }
    &.dropdown-active[data-dropdown-direction='up'] {
        border-radius: 0 0 3px 3px;
    }
}

.selectize-dropdown {
    &.direction-up {
        margin: 1px 0 0 0;
        border-bottom: 0 none;
        border-radius: 3px 3px 0 0;
    }
    &.direction-down {
        margin: -1px 0 0 0;
        border-top: 0 none;
        border-radius: 0 0 3px 3px;
    }
}

.sel-wrapper {
    display: inline-block;
    position: relative;
    .selectize {
        display: inline-block;
        vertical-align: middle;
    }
}

.sel-wrapper-new-component {
    top: 18px;
    width: 300px;
}

.sel-wrapper-tform {
    width: 200px;
}

.selectize-swidth {
    width: 200px;
}

.selectize-mwidth {
    width: 300px;
}

.selectize-lwidth {
    width: 500px;
}

.selectize-products {
    width: 280px;
    font-size: 15px;
    .selectize-dropdown-content {
        &>div {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
        .pname {}
        .tot-quantity {
            display: block;
            padding-top: 3px;
            text-align: left;
            .label.green {
                background: #468847 !important;
            }
        }
    }
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
    font-size: 14px;
}

.selectize-dropdown .active {
    background-color: #c8e5f7;
    color: #000;
    border-right: 2px solid #339fe0;
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.3);
}


/*
  =====================
  Typeahead
  =====================
 */

.tt-hint {
    left: auto !important;
    right: 0 !important;
}

.products-wrapper .tt-dropdown-menu {
    min-width: 268px;
}

.tt-suggestion {
    color: #000;
    text-align: right;
}

.twitter-typeahead .tt-hint {
    color: #fff;
}

.tt-dropdown-menu {
    max-height: 320px;
    overflow-y: auto;
    min-width: 210px;
}

.typeahead-products-search,
.typeahead-clients-suppliers-search {
    text-align: right;
}

.typeahead-owner {
    width: 300px;
    text-align: right;
}

.typeahead-medium {
    width: 300px;
}

.typeahead-xmedium {
    width: 400px;
}

.league-name {
    padding: 0 15px;
    margin: 5px 0;
}

.tt-dataset-clients {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

#date-range-input .caret,
.date-range-picker-input .caret {
    margin-top: 8px;
    margin-right: 5px;
}
