.ui.menu .item.notif-unseen,
.ui.menu .ui.dropdown .menu>.item.notif-unseen {
  // color: #f75151 !important;
  background-color: #ffe4c4 !important;
}

.ui.menu .item.notif-unseen:hover,
.ui.menu .ui.dropdown .menu>.item.notif-unseen:hover {
  background-color: #ffe7c99e !important;
}

.notifications-list {
  tr.notification.unseen {
    background-color: #eeffc9;
  }
}


.top-bar-notifications {
  position: relative;

  .alarm.icon {
    z-index: 1;
    margin-left: 0 !important;
  }
  .count-label {
    position: absolute;

    top: 8px;
    left: 10px;
    //min-width: 1em;
    //min-height: 1em;

    margin-left: 0 !important;
    font-size: 11px;
    z-index: 1;
  }

  .menu {
    width: 350px;
  }

  &.ui.dropdown .menu>.item {
    padding: .9em 1.1em!important;

    &.bordered {
      border-bottom: 1px solid #f7f7f7;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.item .block-item {
  //display: block;
  direction: ltr;
  padding: 8px 5px;
}


.so-fulfillment-wrapper {
  padding-top: 10px;

  // &.highlight {
  //   background: #fffeaa;
  // }

  // &.highlight-animation {
  &.highlight {
    background: #fffeaa;
    // animation: highlight 5s 1;
  }
}

@keyframes highlight {
  0% {
    background: #fffeaa;
  }
  100% {
    background: none;
  }
}
