.po-status {
  background: #efefef;
  padding: 3px 5px;
  border-radius: 15px;
  width: 107px;
  height: 27px;


  .po-status-label {
    display: inline-block;
    text-transform: capitalize;
  }

  .po-status-shape {
    width: 1em; height: 1em;
    display: inline-block;
    margin: 0 5px;
    border-radius: 50%;
    background: transparent;
      position: relative;
      overflow: hidden;
      top: 2px;

    &.status-draft { background: #fff600; }
    &.status-active { background: #69cf34; }
    &.status-finalized { background: #4285f4; }
    &.status-procured { background: #C377E0; }
    &.status-received { background: #C377E0; }
    &.status-void { background: red; }
  }
}
