.simple-calendar {
  .calendar-heading {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;

    a {
      padding: 20px;
    }

    .calendar-title {
      font-size: 2rem;
    }
  }

  table {
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgb(221, 221, 221);
    border-collapse: collapse;
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
  }

  tr {
    border-collapse: collapse;
  }

  th {
    padding: 6px;
    border-bottom: 2px solid rgb(221, 221, 221);
    border-collapse: collapse;
    border-left: 1px solid rgb(221, 221, 221);
    border-right: 1px solid rgb(221, 221, 221);
    border-top: 0px none rgb(51, 51, 51);
    box-sizing: border-box;
    text-align: left;
  }

  td {
    padding: 6px;
    vertical-align: top;
    width: 14%;

    border: 1px solid #ddd;
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
  }

  .day {
    height: 80px;

    .add-order-btn {
      //transition: all 0.1s ease-in-out;
      visibility: hidden;
    }

    &:hover {
      .add-order-btn {
        visibility: visible;
      }
    }
  }

  .wday-0 {}
  .wday-1 {}
  .wday-2 {}
  .wday-3 {}
  .wday-4 {}
  .wday-5 {}
  .wday-6 {}

  .today {
    background: #FFFFC0
  }

  .past {}
  .future {}

  .start-date {}

  .prev-month {
    background: #DDD;
  }
  .next-month {
    background: #DDD;
  }
  .current-month {}

  .has-events {}

  .day-number {
    color: #6a6a6a;
  }

  .mrp-planning-orders {
    min-height: 40px;
  }

  .ui.selection.list .list>.item, .ui.selection.list>.item {
    margin-bottom: 0.5rem;
    color: #fff;

    &:hover {
      color: #afafaf;
    }

    &.status-draft { background: #FBBD08; }
    &.status-active { background: #21BA45; }
    &.status-finalized { background: #2185D0; }

    &.status-planned { background: #cc8502; }
    &.status-in_progress { background: #43a3b7;}
    &.status-finished { background: #A333C8; }
    &.status-done { background: #A333C8; }
    &.status-terminated { background: #DB2828; }
  }
}
