

/*
  ==========================
  Forms
  ==========================
 */

 .form-search {
   text-align: center;
   min-width: 400px;
 }

 .form-search input[type='text'].txt {
   text-align: right;
   height: 35px;
   font-family: 'Droid Arabic Naskh', serif;
   line-height: 30px;
 }

 .page-header .form-search {
  margin-top: 10px;
  margin-bottom: 0;
 }

 .m-width {
  width: 250px;
 }


/* ========================== */




/* Form fields */

//input[type="text"],
//input[type="password"],
//input[type="datetime"],
//input[type="datetime-local"],
//input[type="date"],
//input[type="month"],
//input[type="time"],
//input[type="week"],
//input[type="number"],
//input[type="email"],
//input[type="url"],
//input[type="search"],
//input[type="tel"],
//input[type="color"],
//.uneditable-input {
//  height: 26px;
//}



select {
  height: 40px !important;
}

//input[type="text"],
//input[type="number"],
//input[type="password"],
//input[type="email"],
//input[type="tel"] {
//  height: 40px;
//  font-size: 16px;
//  padding: 5px 6px;
//}




//
//.field .radio label {
//  margin-left: 40px;
//  margin-top: 10px;
//}


.form-actions {
  padding: 19px 20px 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  margin: 20px -20px;

  transition: 0.2s;

  &.fade {
    opacity: 0;
  }
}

.form-actions:before,
.form-actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-actions:after {
  clear: both;
}

.form-actions.fixed {
  margin: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100vw - 50px);
  z-index: 1;
  box-shadow: 0 -2px 5px 0 rgba(0,0,0,.12);
}


