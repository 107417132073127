.sidebar-details-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: rgba(33,33,33,0.0);

  //display: none;
  opacity: 0;
  visibility: hidden;

  //transition: all 0.3ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.2s cubic-bezier(0.600, -0.280, 0.735, 0.045);

  &.md-opaque {
    background-color: rgba(50,50,50,0.5);
    //opacity: .48;
  }
}

.sidebar-details-wrapper {
  .sidebar-details {
    //flex-direction: column;
    //display: flex;
    position: fixed;
    box-shadow: 0 9px 18px 0 rgba(0,0,0,0.18);
    background-color: rgb(255,255,255);
    width: auto;
    //min-width: 400px;
    //max-width: 50%;
    z-index: 102;

    top: 0;
    bottom: 0;
    overflow: auto;

    //transition: all .45s ease-in-out;
    transition: all .3s cubic-bezier(.23,1,.32,1);

    left: 0;
    transform: translate3d(-100%,0,0);
    visibility: hidden;
    opacity: 0;

    &.is-right {
      right: 0;
      left: auto;
      transform: translate3d(100%,0,0);
    }
  }

  &.is-open {
    .sidebar-details {
      transform: translate3d(0,0,0);
      opacity: 1;
      visibility: visible;
    }

    .sidebar-details-backdrop {
      opacity: .48;
      visibility: visible;
    }
  }

  .sidebar-close-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 103;
  }
}

.search-dialog {
  width: 90%;
  height: 90%;
  background: #fff;
  padding: 0;
  border: 0;
}

.__dialog {
  --grey: #767676;
  --grey-dark: #5a5a5a;
  --darkreader-text--grey-darker: #bfaf92;
  --darkreader-border--grey-darker: #766b59;
  --darkreader-bg--soft-shadow-dark: 0px 0.5px 1.4px #0000001a, 0px 1.3px 3.8px rgba(0, 0, 0, .125), 0px 3px 9px rgba(0, 0, 0, .135), 0px 10px 30px rgba(0, 0, 0, .145);

  box-shadow: var(--darkreader-bg--soft-shadow-dark);
  border: 1px solid var(--darkreader-border--grey-darker);
  padding: 0;
  border-radius: 0.5rem;

  & > div {
    padding: 2em 3em;
  }
}

::backdrop {
  background-image: initial;
  background-color: rgba(6, 6, 5, 0.35);
}


:root {
  --base-hue: 210deg;
}

.search-dialog {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
  overlay 0.3s ease-in-out allow-discrete,
  display 0.3s ease-in-out allow-discrete;

  opacity: 0;
  transform: translateY(1em);
}

.search-dialog {
  &::backdrop {
    transition: display 0.3s allow-discrete, overlay 0.3s allow-discrete,
    background-color 0.3s;
    background-color: hsl(0 0 0 / 0);
  }

  &[open]::backdrop {
    background-color: hsl(0 0 0 / 50%);
  }
}

.search-dialog[open] {
  opacity: 1;
  transform: translateY(0);
}

@starting-style {
  .search-dialog[open]{
    opacity: 0;
    transform: translateY(-1em);
  }

  .search-dialog[open]::backdrop {
    background-color: hsl(0 0 0 / 0);
  }
}

.swal-footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  //justify-content: space-between;
}

.avatar-group {
  display: flex;
  align-items: center;
  position: relative;

  .avatar-group-item {
    width: 34px; /* Set avatar size */
    height: 34px;
    border-radius: 50%; /* Make it circular */
    border: 1px solid white; /* Optional: Add a border for better visibility */
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
    transition: .2s ease;
    transition: transform 0.3s ease, z-index 0.3s; /* Smooth transitions */
    z-index: var(--avatar-index, 1); /* Ensure proper layering */

    /* Overlap by reducing margin */
    &:nth-child(n+2) {
      margin-inline-start: -15px;
    }
  }

  &.cascade:hover .avatar-group-item {
    transform: translateX(calc(var(--avatar-index) * -15px)); /* Spread out avatars on hover */
    z-index: calc(var(--avatar-index) + 10); /* Increase z-index on hover*/
  }
}



.uom-label {
  color: #8b8a8a;
  padding: 0.5rem 0.5rem;
  font-size: 0.9rem;
  display: inline-block;
  background-color: #f7f7f7;

  &:empty {
    display: none;
  }
}


.status-simple-label {
  background: #f7f7f7;
  padding: 4px 10px;
  border-radius: 15px;
  //width: 122px;
  //width: 136px;
  height: 29px;
  //display: inline-block;
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  text-align: right;

  &.white-bg {
    background: #fff;
  }


  .status-label {
    display: inline-block;
    text-transform: capitalize;
    color: var(--grey-color);
    font-size: 13px;
    //top: -2px;
    //position: relative;

    font-family: var(--base-font-family);
  }

  .status-shape {
    width: 1em;
    height: 1em;
    display: inline-block;
    //margin: 0 5px;
    border-radius: 50%;
    background: transparent;
    position: relative;
    overflow: hidden;
    top: 2px;
    font-size: 15px;

    &.status-draft {
      background: #FF9800;
    }

    &.status-active {
      background: #2ECC71;
    }

    &.status-terminating {
      background: #000000;
    }

    &.status-finalized {
      background: #1565C0;
    }

    &.status-planned {
      background: #FFC107;
    }

    &.status-finished {
      background: #9575CD;
    }

    &.status-done {
      background: #7E57C2;
    }

    &.status-terminated {
      background: #A93226;
    }

    &.status-fulfilled {
      background: #A333C8;
    }

    &.status-procured {
      background: #BA68C8;
    }

    &.status-received {
      background: #8E44AD;
    }

    &.status-void {
      background: #B71C1C;
    }

    &.status-revised {
      background: #29c0ee;
    }

    &.status-uninvoiced {
      background: #FFB300;
    }

    &.status-invoiced {
      background: #9B59B6;
    }

    &.status-unreceived {
      background: #FBC02D;
    }

    &.status-packed {
      background: #6A1B9A;
    }

    &.status-unpacked {
      background: #F57C00;
    }

    &.status-shipped {
      background: #00838F;
    }

    &.status-ready {
      background: #26A69A;
    }

    &.status-paid {
      background: #6A1B9A;
    }

    &.status-inactive {
      background: #8B0000;
    }

    &.status-returning {
      background: #e2ba5b;
    }

    // WorkOrder
    &.status-in_progress {
      background: #1976D2;
    }

    &.status-paused {
      background: #FF6F00;
    }

    &.status-todo {
      background: #42A5F5;
    }

    //positions
    &.status-open {
      background: #27AE60;
    }

    &.status-on_hold {
      background: #9C27B0;
    }

    &.status-filled {
      background: #2185D0;
    }

    &.status-canceled {
      background: #D32F2F;
    }

    &.status-approved {
      background: #009688;
    }

    &.status-rejected {
      background: #E74C3C;
    }

    &.status-accepted {
      background: #8E44AD;
    }

    &.status-busy {
      background: #795548;
    }

    &.status-started {
      background: #7D3C98;
    }

    &.status-confirmed {
      background: #1E7D32;
    }

    &.status-completed {
      background: #673AB7;
    }

    &.status-submitted {
      background: #673AB7;
    }

    //conflicts
    &.status-investigation_assessment {
      background: #64B5F6;
    }

    &.status-intake_reporting {
      background: #43A047;
    }

    &.status-resolution_follow_up {
      background: #F44336;
    }

    //Alternative Dispute Resolution
    &.status-investigated {
      background: #FBC02D;
    }

    &.status-postponed {
      background: #B71C1C;
    }

    &.status-under_deliberation {
      background: #283593;
    }

    //loans
    &.status-activated {
      background: #2E8B57;
    }

    &.status-declined {
      background: #C0392B;
    }

    &.status-waiting {
      background: #e2ba5b;
    }

    &.status-prospective {
      background: #2185D0;
    }

    &.status-hired {
      background: #A569BD;
    }

    // tasks priority
    &.status-high {
      background: #D50000;
    }

    &.status-med {
      background: #FFEB3B;
    }

    &.status-low {
      background: #757575;
    }

    //absence log
    &.status-attendant {
      background: #4CAF50;
    }
    &.status-absent {
      background: #D50000;
    }
    &.status-withdrawn {
      background: #546E7A;
    }
    &.status-time_off {
      background: #FF7043;
    }
    &.status-vacation {
      background: #FFD54F;
    }
    &.status-holiday {
      background: #039BE5;
    }

    &.status-work_day {
      background: #43A047;
    }
    &.status-overtime {
      background: #C62828;
    }
    &.status-on_leave {
      background: #FBC02D;
    }

    &.status-yes {
      background: #43A047;
    }
    &.status-no {
      background: #546E7A;
    }
      
  }
}


.total-items-list {
  clear: both;
  width: 100%;
  display: flex;
  flex-direction: column;

  .item {
    padding: 10px;
    text-align: left;
    //float: left;
    //clear: both;
    min-width: 300px;

    display: flex;
    align-self: end;
    align-items: center;


    &.green {
      background: green;
      color: #fff;
    }

    &.blue {
      background-color: #0d75bc;
      color: #fff;
    }

    .text {
      width: 140px;
      display: inline-block;
      text-align: right;
      margin-right: 20px;
    }

    &.error {
      background: #fde2e2;
    }

    & .num {
      min-width: 150px;
    }
  }
}

.simple-box {
  margin-bottom: 3rem;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #dce1e6;
  border-radius: 3px;

  //@extend .animated;
  //@extend .slideInUp;


  .simple-box-inner {
    padding-bottom: 5px;

    .table {
      margin-bottom: 0;
      border: none;
    }

    .ui.header {
      line-height: 1.5em;
      margin-bottom: 0;
      padding: 15px;
      border-bottom: 1px solid rgba(34, 36, 38, .15);
      background: #f9fafb;

      &::after {
        display: block;
        content: "";
        clear: both;
      }
    }
  }

  .simple-box__footer {
    margin-top: 15px;
    padding: 15px;
    border-top: 1px solid rgba(34, 36, 38, .15);

    &:empty {
      display: none;
    }
  }
}


.card.statistic {
  direction: ltr;

  .value {
    font-size: 1.9rem;
    margin-bottom: 1rem;
  }

  .change {
    font-size: 1.2rem;


    &.positive {
      color: green;
    }

    &.negative {
      color: red;
    }
  }

  .card-text {
    color: #929292;
    font-weight: 600;
  }
}


.d-block-only-one { display: none; }
.d-block-only-one:only-of-type { display: block; }

.attendance-card {
  --ready-color: rgb(146 146 146);
  --clocked_in-color: rgb(56, 142, 60, 1);
  --clocked_out-color: rgb(66, 165, 245, 1);
  --withdrawn-color: rgb(251, 192, 45, 1);
  --absent-color: rgb(211, 47, 47, 1);

  position: relative;
  color: #212529;
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  overflow: hidden; // for the progress bar to blend with the border

  &:hover {
    background: #f9fafb;

    .attendance-card__body {
      cursor: pointer;
    }
  }

  .attendance-card__progress-bar {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--width, 0%);
    max-width: 100%;
    height: 3px;
    background: var(--color, #007bff);
  }
}

.attendance-status-badge {
  display: inline-block;
  vertical-align: baseline;

  line-height: 1;
  padding: 5px 10px;

  border-radius: 5px;
  border: 1px solid #dce1e6;

  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;

  // blueGrey
  --color: rgb(96, 125, 139, 1);
  color: var(--color);
  border-color: var(--color);
  background: color-mix(in srgb, var(--color) 10%, transparent);

  // grey.shade400
  &.ready {
    color: var(--ready-color);
    border-color: var(--ready-color);
    background: color-mix(in srgb, var(--ready-color) 10%, transparent);
  }
  //green.shade700
  &.clocked_in {
    color: var(--clocked_in-color);
    border-color: var(--clocked_in-color);
    background: color-mix(in srgb, var(--clocked_in-color) 10%, transparent);
  }
  // blue.shade400
  &.clocked_out {
    color: var(--clocked_out-color);
    border-color: var(--clocked_out-color);
    background: color-mix(in srgb, var(--clocked_out-color) 10%, transparent);
  }
  //yellow.shade700
  &.withdrawn {
    color: var(--withdrawn-color);
    border-color: var(--withdrawn-color);
    background: color-mix(in srgb, var(--withdrawn-color) 10%, transparent);
  }
  // red.shade700
  &.absent {
    color: var(--absent-color);
    border-color: var(--absent-color);
    background: color-mix(in srgb, var(--absent-color) 10%, transparent);
  }
}


button .show-when-disabled { display: none; }
button[disabled] .show-when-disabled { display: initial; }

button .show-when-enabled { display: initial; }
button[disabled] .show-when-enabled { display: none; }


:root {
  --priority-base-color: #b8bebf;
  --priority-high-color: #0640d3;
  --priority-med-color: #459905;
  --priority-low-color: #3a3d3e;
}

/* Default: All dots have the same color */
.priority-icon .dot {
  fill: var(--priority-base-color);
  display: block;
}

/* Show all three dots for high priority */
.priority-icon.high .high,
.priority-icon.high .medium,
.priority-icon.high .low {
  fill: var(--priority-high-color);
}

/* Show two dots for medium priority */
.priority-icon.med .high,
.priority-icon.med .medium {
  fill: var(--priority-med-color);
}
.priority-icon.med .low {
  fill: var(--priority-base-color);
}

/* Show one dot for low priority */
.priority-icon.low .high {
  fill: var(--priority-low-color);
}
.priority-icon.low .medium,
.priority-icon.low .low {
  fill: var(--priority-base-color);
}


:root {
  --task-status-in_progress: #43a3b7;
  --task-status-paused: #f2712c;
  --task-status-todo: #0b9bf4;
  --task-status-done: #a333c8;
}

.task-card {
  //border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  //--tw-border-opacity: 1;
  //border-color: rgba(107, 114, 128, var(--tw-border-opacity));

  border-right-width: 3px !important;

  &.todo {
    border-right-color: var(--task-status-todo) !important;
  }
  &.in_progress {
    border-right-color: var(--task-status-in_progress) !important;
  }
  &.paused {
    border-right-color: var(--task-status-paused) !important;
  }
  &.done {
    border-right-color: var(--task-status-done) !important;
  }
}

// tasks pie chart

:root {
  --hsl-purple: 264.8, 76.7%, 76.5%;
  --color-theme: hsla(var(--hsl-purple), 1);
  --hsl-green: 134.1, 39.1%, 59.4%;
  --color-green-60: hsl(var(--hsl-green));
  --color-green: var(--color-green-60);
}

bc-pie-chart {
  vertical-align: bottom;
  display: inline-block;
  inline-size: 1.125em;
  block-size: 1.125em
}

bc-pie-chart circle.pie {
  fill: rgba(0, 0, 0, 0);
  stroke: var(--color-theme, var(--color-green));
  opacity: .5
}

bc-pie-chart circle.slice {
  fill: rgba(0, 0, 0, 0);
  stroke: var(--color-theme, var(--color-green))
}

bc-pie-chart svg {
  border-radius: 50%;
  transform: rotate(-90deg)
}

.todo-progress .todo-progress__ratio {
  position: relative;
  inset-block-start: -0.33em;
  inset-inline-start: 0;
  font-size: .625em;
}

.btn-accept {
  background: #28A745 !important;
}
.btn-activate {
  background: #007BFF !important;
}
.btn-approve {
  background: #28A745 !important;
}
.btn-busy {
  background: #FFC107 !important;
}
.btn-redirect-then-approve {
  background: #FD7E14 !important;
}
.btn-reject{
  background: #DC3545 !important;
}
.btn-revise{
  background: #6F42C1 !important;
}
.btn-done{
  background: #6C757D !important;
}

/**pay-slip**/
.btn-finalize-all{
  background: #6F42C1 !important;
}
.btn-confirm-all{
  background: #28A745 !important;
}
.btn-pay-all{
  background: #28A745 !important;
}

/*candidates*/
.btn-re-apply {
  background: #FD7E14 !important;
} 
.btn-hire {
  background: #28A745 !important;
} 
.btn-mark-as-prospective {
  background: #007BFF !important;
} 
.btn-make-offer {
  background: #6F42C1 !important;
}

/* employee terminationa */
.btn-reconciliation {
  background: #007BFF !important;
} 
.btn-confirm {
  background: #28A745 !important;
}

/* tasks */
.btn-start {
  background: #28A745 !important;
}
.btn-pause {
  background: #FFC107 !important;
}
.btn-resume {
  background: #007BFF !important;
}
.btn-complete {
  background: #28A745 !important;
}
.btn-done {
  background: #6C757D !important;
}
.btn-undo-done {
  background: #FD7E14 !important;
}

