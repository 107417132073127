/* Frames */

ul.frames {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Each item */
ul.frames li {
    background-color: #f8f8f8;
    background: -webkit-linear-gradient(top, #f8f8f8 80%, #f0f0f0);
    background: -moz-linear-gradient(top, #f8f8f8 80%, #f0f0f0);
    background: linear-gradient(top, #f8f8f8 80%, #f0f0f0);
    box-shadow: inset 0 -1px 0 #e2e2e2;
    padding: 7px 20px;

    cursor: pointer;
    overflow: hidden;
}

ul.frames .name,
ul.frames .location {
    overflow: hidden;
    height: 1.5em;

    white-space: nowrap;
    word-wrap: none;
    text-overflow: ellipsis;
}

ul.frames .method {
    color: #966;
}

ul.frames .location {
    font-size: 0.85em;
    font-weight: 400;
    color: #999;
}

ul.frames .line {
    font-weight: bold;
}

/* Selected frame */
ul.frames li.selected {
    background: #38a;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 rgba(255, 255, 255, 0.01), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}

ul.frames li.selected .name,
ul.frames li.selected .method,
ul.frames li.selected .location {
    color: white;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}

ul.frames li.selected .location {
    opacity: 0.6;
}

/* Iconography */
ul.frames li {
    padding-left: 60px;
    position: relative;
}

ul.frames li .icon {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 15px;

    text-align: center;

    background: white;
    border: solid 2px #ccc;

    font-size: 9pt;
    font-weight: 200;
    font-style: normal;

    position: absolute;
    top: 14px;
    left: 20px;
}

ul.frames .icon.application {
    background: #808090;
    border-color: #555;
}

ul.frames .icon.application:before {
    content: 'A';
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
