.model--stats-wrapper {
  background-color: #fff;
  //padding: 10px;
  border: 1px solid #f2f2f2;
}

.model--stats-block {
  font-family: var(--base-font-family);
  text-align: right;
  border-left: 1px solid #f2f2f2;
  padding: 20px;
  line-height: 1em;

  &.no-border {
    border-left: none;
  }

  .text {
    margin-bottom: 20px;
  }
}

.model--info-wrapper {
  background-color: #fff;
  border: 1px solid #f2f2f2;
  padding: 30px;
  margin-bottom: 20px;

  .model--info-header {
    //margin: calc(2rem - .14285em) 0 1rem;
    padding-bottom: 20px;
    line-height: 1.5em;
    font-weight: 600;
    font-family: var(--base-font-family);

    //border-bottom: 1px solid rgba(34,36,38,.15);
    color: #686868;

    &:first-child {
      margin-top: 20px;
    }
  }

  .model--info-data {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }
  }
}


.model--name {
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.5em;
}

.timeline {
  direction: ltr;
  padding: 20px;
  background: #fff;
  border: 1px solid #f2f2f2;

    ul, ol {
    padding: 20px;
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 0.75rem;
  }
}

.contain {
  max-width: 73.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.contain::after {
  clear: both;
  content: "";
  display: table;
}


@media screen and (min-width: 48rem) {
  .contain {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

@media screen and (min-width: 73.75rem) {
  .contain {
    padding-left: 0;
    padding-right: 0;
  }
}


.timeline ul li {
  width: 100%;
  position: relative;
  list-style-type: none;
  padding-bottom: 3.75rem;
  border-left: 1px solid #7d97ad;
}

.timeline ul li::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #4178be;
  background-color: #a1caff;
  transform: translateX(-50%);
}

@media screen and (min-width: 48rem){
  .timeline ul li {
    padding-bottom: 1.5rem;
  }
}





.timeline ul li div {
  bottom: 7px;
  width: 100%;
  position: relative;
  padding-left: 2.25rem;
  height: 10.5rem;
}


@media screen and (min-width: 48rem) {
  .timeline ul li div {
    height: 10.5rem;
    width: 80%;
    padding-left: 3rem;
  }
}

@media screen and (min-width: 73.75rem) {
  .timeline ul li div {
    width: 60%;
  }
}


.timeline ul li h6 {
  color: #757C81;
}


@media screen and (min-width: 48rem) {
  h6, .h6 {
    font-size: 0.8125rem;
    line-height: 1.5rem;
  }
}


.mb-half {
  margin-bottom: 0.75rem;
}

h4, .h4 {
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 400;
}

@media screen and (min-width: 48rem) {
  h4, .h4 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

p small, p.small {
  font-size: 0.8125rem;
  line-height: 1.5rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}


// .white {
//   color: #fff !important;
// }

.black {
  color: #000 !important;
}


.contain.no-padding-bottom {
  padding-bottom: 0;
}
.contain.no-padding-top {
  padding-top: 0;
}
