.tasks-item {
  position: relative;
  background: #fff;
  padding: 10px;

  &.complete {
    background-color: #f3f3f3;
    font-style: italic;

    //.btn {
    //  display: none !important;
    //}
  }
}
