.chat-textarea {
  height: 44px;
  overflow: hidden;
  resize: none !important;
}

.chat-panel {
  height: 400px;
  overflow-y: auto;
  margin-bottom: 0;


  .chat-body {
    .event {
      border-bottom: 1px solid #ccc;
      margin-bottom: 1px;
      padding: 10px;

      .content .extra.text {
        word-wrap: break-word;
        word-break: break-all;
      }
    }

    .event.owner {
      direction: rtl;
      text-align: right;
      background: #f1f1f1;

      .content {
        text-align: right;
      }

      .label+.content {
        margin: .5em 1.14285714em .35714286em 1.14285714em;
      }

      .content .summary > .date {
        direction: ltr;
      }


    }

    .event.other {
      direction: ltr;
      text-align: left;
      background: #e6e6e6;


      .content {
        text-align: left;
      }

      .content .summary > .date {
        direction: ltr;
      }

      .content .extra.text {
        text-align: left;
        direction: rtl;
      }
    }
  }
}


.chatrooms_show {
  #page {
    margin-bottom: 0;
  }
  #footer {
    margin-bottom: 0;
  }
}
