// app/views/fi/reports/journal.html.erb

.journal-entry .post-to-ledger-btn {
  display: inline-block;
}
.journal-entry .posted-label {
  display: none;
}

.journal-entry.posted-to-ledger .post-to-ledger-btn {
  display: none;
}

.journal-entry.posted-to-ledger .posted-label {
  display: inline-block;
}
