.product-selling-price-items-wrapper {
  margin-top: 20px;
}

.product-selling-price--item {
  padding: 10px 18px;
  margin-bottom: 5px;
  background-color: #fff;

  .product-selling-price--product-name {
    width: 50%;
  }

  .product-selling-price--product-name,
  .product-selling-price--product-price,
  .product-selling-price--product-actions {
    padding-left: 20px;
    line-height: 2.4em;
  }
  .product-selling-price--product-price {
    width: 150px;
  }

  .product-selling-price--product-form {
    line-height: 2.4em;
  }

  .product-selling-price--product-actions {
    display: none;
  }

  &:hover {
    background-color: #f1f1f1;
    border-right: 1px solid #91c2f5;
    .product-selling-price--product-actions {
      display: block;
    }
  }

  &.edit-mode {
    background-color: #f1f1f1;
    border-right: 1px solid #91c2f5;
  }
}
// product-selling-price--product-form
